
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































.flexible-assert {
  transition: opacity 0.5s 0.4s $ease-softer;

  &.before-appear {
    opacity: 0;
  }
}

.flexible-assert__picture {
  display: block;
  width: 3rem;
  margin: 0 auto $spacing * 0.75;
}

.flexible-assert__title {
  @extend %text-center;
}
